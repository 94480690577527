import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
        // backgroundColor: "#F5F5F5",
        paddingBottom: "80px",
    },
    text: {
        order: 2,
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: theme.breakpoints.values["sm"],
    },
    headline: {
        fontWeight: "bold",
    },
    vidContainer: {
        order: 1,
        flex: 2,
        position: "relative",
        height: "50vh",
        // maxWidth: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "fill-available",
        // minWidth: theme.breakpoints.values["sm"],
        // [theme.breakpoints.down('lg')]: {
        //     display: "none"
        // },
        // [theme.breakpoints.only('lg')]: {
        //     display: "block"
        // },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "1000px",
        },
    },
    youTubeFrame: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
}));

const ExplainerVidSection: React.FC = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="xl" className={classes.root}>
            <div className={classes.text}>
                <Typography
                    className={classes.headline}
                    variant="h5"
                    paragraph={true}
                    align="center"
                >
                    A totally new idea for managing traffic congestion
                </Typography>
            </div>
            <div className={classes.vidContainer}>
                <iframe
                    className={classes.youTubeFrame}
                    src="https://www.youtube.com/embed/MGMOjgtWqeQ?rel=0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                    allowFullScreen
                    title="Explainer Video"
                ></iframe>
            </div>
        </Container>
    );
};

export default ExplainerVidSection;
