import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CarIcon from '@material-ui/icons/DirectionsCar'
import FlowerIcon from '@material-ui/icons/LocalFlorist';
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Bulldozer } from '../../assets/bulldozer.svg';
import { ReactComponent as Unequal } from '../../assets/unequal.svg';

const iconFontSize = { fontSize: 80 };

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "100px",
        },
        headline: {
            fontWeight: "bold"
        },
        text: {
        }
    }),
);

export type specificBenefitProps = {
    icon?: "car" | "flower" | "bulldozer" | "unequal",
    headline: string,
    text: string,
    headlineAlignment?: "left" | "right" | "center" | undefined
}

const getIcon = (target: string) => {
    switch (target) {
        case ("car"): {
            return <CarIcon style={iconFontSize} htmlColor="#D10000" />
        }
        case ("flower"): {
            return <FlowerIcon style={iconFontSize} htmlColor="#00DA23" />
        }
        case ("bulldozer"): {
            return <SvgIcon style={iconFontSize}><Bulldozer /></SvgIcon>
        }
        case ("unequal"): {
            return <SvgIcon style={iconFontSize}><Unequal /></SvgIcon>
        }
        default: {
            return <span></span>
        }
    }
}

export const SpecificBenefit: React.FC<specificBenefitProps> = (props) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {props.icon ? getIcon(props.icon) : ""}
            <Typography
                variant="h5"
                className={classes.headline}
                align={props.headlineAlignment ? props.headlineAlignment : "center"}
            >
                {props.headline}
            </Typography>
            <Typography
                variant="body2"
                className={classes.text}
            >
                {props.text}
            </Typography>
        </Box>

    )
}

export default SpecificBenefit

