import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
// import driver from "../../assets/relaxedDriver.png";
import muni from "../../assets/urbanPlanning.png";
import traveler from "../../assets/relaxedWithCoffee.png";
import routeNotReady from "../../assets/growingSprouts.png";
import {
    SpecificBenefit,
    specificBenefitProps,
} from "../SpecificBenefit/SpecificBenefit";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "center",
    },
    titleArea: {
        display: "flex",
        flexDirection: "column",
    },
    body: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        marginBottom: "40px",
        justifyContent: "center",
    },
    benefitsContainer: {
        flex: 1,
        maxWidth: theme.breakpoints.values.md,
        minWidth: theme.breakpoints.values.sm,
        [theme.breakpoints.down("sm")]: {
            minWidth: "unset",
        },
        [theme.breakpoints.down("md")]: {
            paddingRight: "25px",
        },
        [theme.breakpoints.only("md")]: {
            paddingRight: "25px",
        },
    },
    photo: {
        flex: 1,
        maxWidth: "500px",
        objectFit: "cover",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("md")]: {
            order: -1,
        },
        [theme.breakpoints.only("md")]: {
            order: -1,
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "750px",
        },
    },
}));

const imageOptions = {
    driver: muni,
    traveler: traveler,
    routeNotReady: routeNotReady,
};

export type subPageOverviewSectionProps = {
    pageTitle: string;
    subtitle: string;
    image?: keyof typeof imageOptions;
    benefits?: specificBenefitProps[];
    className?: string;
};

const showBenefits = (benefitsArray: specificBenefitProps[] | undefined) => {
    if (!benefitsArray) {
        return "";
    }
    return benefitsArray.map((item, index) => {
        return (
            <SpecificBenefit
                headline={item.headline}
                headlineAlignment={
                    item.headlineAlignment ? item.headlineAlignment : undefined
                }
                text={item.text}
                key={index}
            ></SpecificBenefit>
        );
    });
};

const SubPageOverviewSection: React.FC<subPageOverviewSectionProps> = (
    props: subPageOverviewSectionProps
) => {
    const classes = useStyles();

    const imageOptions = {
        driver: muni,
        traveler: traveler,
        routeNotReady: routeNotReady,
    };

    return (
        <Container className={classes.root} maxWidth={"xl"}>
            <div className={classes.titleArea}>
                <Typography variant="h3" paragraph={true} align="center">
                    {props.pageTitle}
                </Typography>
                <Typography variant="subtitle1" paragraph={true} align="center">
                    {props.subtitle}
                </Typography>
            </div>
            <div className={classes.body}>
                <div className={classes.benefitsContainer}>
                    {showBenefits(props.benefits)}
                </div>
                {props.image ? (
                    <img
                        src={imageOptions[props.image]}
                        className={classes.photo}
                        alt="This could be you"
                    ></img>
                ) : (
                    <></>
                )}
            </div>
        </Container>
    );
};

export default SubPageOverviewSection;
