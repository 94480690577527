//Google Maps API key: AIzaSyCoqfVoYFNY4S6S-9dCoNdF4vFnm0HfGvU (obviously not the right place to store this permanently)
//Note that documentation for the google map node module is here (looks similar to react-google-maps, but that is the OLD version and not used here) 
//https://react-google-maps-api-docs.netlify.app/#section-introduction
//for googleMapsAdditionalOptions - properties are here: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions

import React, { useState, useCallback } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import RouteDisplayMap from '../RouteDisplayMap/RouteDisplayMap';
import DefaultButton from '../DefaultButton/DefaultButton';
import useGetRouteData from '../../hooksAndHelpers/useGetRouteData';
import chooseableRoutes from '../../assets/chooseableRoutes';
import { useQueryParams, StringParam } from 'use-query-params';
import {UrlQueryString} from '../../sharedTypes/sharedTypes'
import getRouteFromRouteId from '../../hooksAndHelpers/getRouteFromRouteId';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			'& > *': {
				margin: theme.spacing(1),
			},
			display: "grid",
			gridTemplateColumns: `3fr 2fr) `,
			columnGap: "10px",
			width: "100%",
			marginBottom: "40px",
			[theme.breakpoints.down('sm')]: {
				gridTemplateColumns: '1fr',
				gridTemplateRows: "unset"
			},
		},
		mapContainer: {
			gridRow: "1 / 3",
			width: "100%",
			minHeight: "500px",
			minWidth: "500px",
			[theme.breakpoints.down('sm')]: {
				gridColumn: 'unset',
				gridRowStart: "2",
				marginBottom: "40px",
				minHeight: "unset",
				height: "80vh",
				minWidth: "unset"
			},
		},
		actualMap: {
			width: "100%",
			height: "100%"
		},
		chooseRouteGroup: {
			gridColumn: "2 / 3",
			gridRow: "1 / 2",
			maxWidth: theme.breakpoints.values.md,
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.down('sm')]: {
				gridColumn: 'unset',
				gridRowStart: "1",
				marginBottom: "40px"
			},
		},
		describeOtherRouteGroup: {
			gridColumn: "2 / 3",
			gridRow: "2 / 3",
			width: "100%",
			maxWidth: theme.breakpoints.values.md,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			[theme.breakpoints.down('sm')]: {
				gridColumn: 'unset',
				gridRow: "unset"
			},
		}
	}),
);

// const apiKey = "AIzaSyCoqfVoYFNY4S6S-9dCoNdF4vFnm0HfGvU"

const googleMapsAdditionalOptions = {
	clickableIcons: false,
	fullscreenControl: false,
	mapTypeControl: false,
	streetViewControl: false,
	controlSize: 24,
	center: { lat: 30.292, lng: -97.729 }, //default to Austin for center
	zoom: 10 //default to 10 for zoom (city-level zoom)
}

type RouteChooserSectionProps = {
	driverOrTraveler: "driver" | "traveler"
}

const RouteChooserSection: React.FC<RouteChooserSectionProps> = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	//state
	const queryParams: UrlQueryString = {routeId: StringParam}
    const [query, setQuery] = useQueryParams(queryParams)
	const [chosenRoute, setChosenRoute] = useState(getRouteFromRouteId(query.routeId) || chooseableRoutes[0])
	const [suggestedRoute, setSuggestedRoute] = useState("");
	const googleMapsApiLoaded = true;
	//moved the google maps API to the top level index.html since need it for basically evey page anyway
	// const [ googleMapsApiLoaded ] = useScriptv2(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=&v=weekly`)
	const displayedRouteData = useGetRouteData(chosenRoute, googleMapsApiLoaded)
	//end state

	const handleChosenRouteClick = useCallback((routeId: string) => {
		setChosenRoute(getRouteFromRouteId(routeId))
		setQuery({routeId: routeId})
	}, [setChosenRoute, setQuery])

	const handleSubmit = async () => {
		
			//TODO: Add some failure handling here later
			//this is a comment to trigger a rebuild (stupid trailing slashes!)
			const SONDAYY_API_URL = process.env.REACT_APP_SONDAYY_API_URL;
	
			const data = {
				routeDescription: suggestedRoute,
				driverOrTraveler: props.driverOrTraveler
			}
	
			const response = await fetch(`${SONDAYY_API_URL}/suggestedroutes/create`, {
				method: 'POST',
				body: JSON.stringify({payload: data}),
				headers: {
					'Content-Type': 'application/json'
				}
			})
	
			console.log(response)
	
			if (!!response) { setSuggestedRoute("We'll look into it - thanks for the suggestion!")
			}	
	}

	return (
		<Container
			className={classes.root}
			maxWidth={"xl"}
		>
			<div className={classes.mapContainer}>
				{
					googleMapsApiLoaded ? 
					<RouteDisplayMap
						routeId={chosenRoute.routeId}
						directionsData={chosenRoute.cachedRouteData || displayedRouteData}
						mapOptions={googleMapsAdditionalOptions}
						height={smallScreen ? '80vh' : "500px"}
					/>	
					: <Skeleton variant="rect" width={200} height={200}></Skeleton>
				}
			</div>
			<div className={classes.chooseRouteGroup}>
				<Typography
					variant="h4"
					paragraph={true}
					align="center">
					Choose your normal route to work
                </Typography>
				<TextField
					id="chosenRouteField"
					select={true}
					value={chosenRoute.routeId || ""}
					fullWidth
					variant="outlined"
					size="small"
					onChange={(e) => handleChosenRouteClick(e.target.value)}
				>
					{chooseableRoutes.map((routeChoice) =>
						<MenuItem value={routeChoice.routeId} key={routeChoice.routeId}>{routeChoice.label}</MenuItem>
					)}
				</TextField>
			</div>
			<div className={classes.describeOtherRouteGroup}>
				<Typography
					variant="subtitle1"
					paragraph={true}
					align="center">
					Don't see your normal commute? Let us know and we'll see if we can add it.
                </Typography>
				<TextField
					id="describeOtherRoute"
					multiline={true}
					rows={3}
					fullWidth
					variant="outlined"
					value={suggestedRoute}
					onChange={(e) => setSuggestedRoute(e.target.value)}
				>
				</TextField>
				<DefaultButton
				 onClick={handleSubmit} 
				 >
					Suggest Route
				</DefaultButton>
			</div>
		</Container>

	)
}

export default RouteChooserSection



