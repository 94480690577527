import { RouteChoice} from '../sharedTypes/sharedTypes';
import chooseableRoutes from '../assets/chooseableRoutes';


const getRouteFromRouteId = (routeId: string) => {
    const matchingRoute: RouteChoice[] = chooseableRoutes.filter((route => route.routeId === routeId))
    //should only ever have one route matching the id
    return matchingRoute[0]
}

export default getRouteFromRouteId
