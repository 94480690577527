import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import lowTraffic from "../../assets/lowTraffic.png";
import highTraffic from "../../assets/highTraffic.png";
import lowTrafficPortrait from "../../assets/lowTrafficPortrait.png";
import highTrafficPortrait from "../../assets/highTrafficPortrait.png";
import { SpecificBenefit } from "../SpecificBenefit/SpecificBenefit";
import DefaultButton from "../DefaultButton/DefaultButton";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-around",
        alignItems: "center",
    },
    carouselLabel: {
        color: "black",
        backgroundColor: "#C4C4C4",
        top: "37%",
        left: "-30%",
        right: "0",
        margin: "0 auto",
        position: "absolute",
        zIndex: 999,
        width: "fit-content",
        padding: "15px",
        borderRadius: "8px",
        opacity: "73%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            left: "0%",
            padding: "5px",
        },
        [theme.breakpoints.down("xs")]: {
            top: "5%",
            borderRadius: "0px",
        },
    },
    titleArea: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    subHeading: {
        alignSelf: "flex-start",
        marginTop: "20px",
        [theme.breakpoints.down("xs")]: {
            order: -1,
        },
    },
    body: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: "25px",
        },
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    left: {
        flex: 1,
        display: "flex",
        alignItems: "center",
    },
    right: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "& > *": {
            marginTop: "20px",
        },
        "& img": {
            maxWidth: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            alignItems: "center",
        },
    },
}));

export const CarouselLabel: React.FC<{ label: string }> = (props: {
    label: string;
}) => {
    const classes = useStyles();
    return (
        <div className={classes.carouselLabel}>
            <Typography variant="h3" paragraph={false} align="center">
                {props.label}
            </Typography>
        </div>
    );
};

const OverviewSection: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Container className={classes.root}>
            <div>
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop={true}
                    useKeyboardArrows={true}
                    autoPlay={true}
                    stopOnHover={true}
                    swipeable={true}
                    emulateTouch={true}
                    showArrows={isMdScreen}
                >
                    <div>
                        <img
                            src={isMdScreen ? lowTraffic : lowTrafficPortrait}
                            alt="Low Traffic"
                        ></img>
                        <CarouselLabel label="Sondayy Traffic"></CarouselLabel>
                    </div>
                    <div>
                        <img
                            src={isMdScreen ? highTraffic : highTrafficPortrait}
                            alt="High Traffic"
                        ></img>
                        <CarouselLabel label="Today's Traffic"></CarouselLabel>
                    </div>
                </Carousel>
            </div>
            <div className={classes.titleArea}>
                <Typography variant="h3" paragraph={true} align="center">
                    Why can't every day be like Sondayy? 🌴
                </Typography>
            </div>
            <Typography
                className={classes.subHeading}
                variant="h5"
                paragraph={true}
                align="center"
            >
                Ever notice how easy it is to drive around town on a Sunday?
            </Typography>
            <div className={classes.body}>
                <div className={classes.left}>
                    <Typography variant="body1" paragraph={true} align="left">
                        On Sundays there’s nothing but you, the road, and the
                        day’s adventure. Fewer cars mean safer, faster trips and
                        cleaner air for everyone. Sondayy makes every day more
                        like Sunday, by paying you 💎 for your time (not) on the
                        road.
                    </Typography>
                </div>
                <div className={classes.right}>
                    <SpecificBenefit
                        headline="Counties and Municipalities"
                        headlineAlignment="left"
                        text="Reduce traffic right now on your most congested roadways... without the delay and expense of road construction 🚧. Sondayy is the cheapest, most flexible and greenest way to serve your citizens’ transportation needs."
                    ></SpecificBenefit>
                    <DefaultButton onClick={() => {}} variant="outlined">
                        <Link
                            to={"/driverspage"}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            Learn More
                        </Link>
                    </DefaultButton>
                    <SpecificBenefit
                        headline="Sondayy Travelers"
                        headlineAlignment="left"
                        text="It pays to be flexible! Sondayy puts cash in your pocket for not driving 💰. Get paid to work remotely, take public transportation or commute during off peak hours. Or, Sondayy can even find you a carpool that’s actually convenient! 🦄"
                    ></SpecificBenefit>
                    <DefaultButton onClick={() => {}} variant="outlined">
                        <Link
                            to={"/travelerspage"}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            Learn More
                        </Link>
                    </DefaultButton>
                </div>
            </div>
        </Container>
    );
};

export default OverviewSection;
