import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {InputLabelProps} from '@material-ui/core/InputLabel'; //need this for the type definition
import Typography from '@material-ui/core/Typography';
import DefaultButton from '../DefaultButton/DefaultButton';

//TODO: Add Validation and error handling

const useStyles = makeStyles((theme: Theme) => 
(
    {
        root: {
            padding: "20px",
            border: "1px solid black",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            maxWidth: "500px",
            [theme.breakpoints.down('sm')]: {
                border: "none"
            },
        },
        nothing: {},
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                width: "100%"
            },
        },
        headline: {
            flexBasis: "100%",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold"
        },
        fullWidth: {
            flexBasis: "100%"
        },
        postalCode: {
            marginRight: "auto"
        },
        buttonContainer: {
            flexBasis: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px"
        },
        small: {
            fontSize: "5px"
        }

    })
);

const ContactUsSection: React.FC = () => {

    const classes = useStyles();
    //state
    const [formValues, setFormValues] = useState<{[key: string]: any}>({})
    //end state
    
    type StyleProps = keyof typeof classes

    type Field = {
        autoComplete?: string,
        id: string,
        label: string,
        className?: StyleProps
        InputLabelProps?: InputLabelProps
    }

    const fields: Field[] = [
        {autoComplete: "given-name", id: "firstName", label: "First Name" },
        {autoComplete: "family-name", id: "lastName", label: "Last Name" },
        {autoComplete: "postal-code", id: "postalCode", label: "Zip Code", className: "postalCode" },
        {autoComplete: "email", id: "email", label: "Email" },
        {id: "message", label: "How can we help you?", className: "fullWidth", InputLabelProps: {shrink: true} },
    ]

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target;
        setFormValues(formValues => ({...formValues, [id]: value}))
    }

    const handleSubmit = async () => {

		//TODO: Add some failure handling here later
		//Adding this comment to force a rebuild. Nothing is ever simple
		const SONDAYY_API_URL = process.env.REACT_APP_SONDAYY_API_URL;
        const contactRequest = {...formValues}
        
        // const response = `Request is ${JSON.stringify(contactRequest)}`;
		const response = await fetch(`${SONDAYY_API_URL}/contactus/create`, {
			method: 'POST',
			body: JSON.stringify({payload: contactRequest}),
			headers: {
				'Content-Type': 'application/json'
			}
		})
        console.log(response)
        const resetFormValues = {
            firstName: "",
            lastName: "",
            postalCode: "",
            email: "",
            message: "Thank you for your message!",
        }
        setFormValues(resetFormValues)
	}

    const createTextField = (id: string, label: string, className: StyleProps, InputLabelProps: Partial<InputLabelProps>, index: number) => {
        return (
            <TextField
            className={`${classes.textField} ${classes[className] || ""}`}
            InputLabelProps={InputLabelProps}
            id={id}
            label={label}
            value={formValues[id] || ""}
            multiline={id === "message" ? true : false}
            rows={4}
            onChange={handleChange}
            key={index}
        />    
        )
    }

  return (
    <div className={classes.root}>
        <Typography 
            className={classes.headline}
            align="center"
            variant="h6" 
        >
            What are you thinking about?
        </Typography>
        {fields.map((item, index) => {
            const {id, label, className = "nothing", InputLabelProps} = item;
            return (
                createTextField(id, label, className, InputLabelProps || {}, index)
            )
        })}
        <div
            className={classes.buttonContainer}
        >
            <DefaultButton
                onClick={handleSubmit}
            >
                Submit
            </DefaultButton>
        </div>

    </div>
  );
}

export default ContactUsSection