import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import HeaderNav from '../HeaderNav/HeaderNav';
import FounderQuoteSection from '../FounderQuoteSection/FounderQuoteSection';
import CtaSection from '../CtaSection/CtaSection';
import FooterNav from '../FooterNav/FooterNav';
import SocialShareSection from '../SocialShareSection/SocialShareSection';
import RouteLibrarySection from '../RouteLibrarySection/RouteLibrarySection';
// import chooseableRoutes from '../../assets/chooseableRoutes';
import pageChoices from '../../assets/pageChoices';
import getRouteFromRouteId from '../../hooksAndHelpers/getRouteFromRouteId';
import { useQueryParams, StringParam } from 'use-query-params';
import {UrlQueryString} from '../../sharedTypes/sharedTypes'


const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            }
        },
    }),
);


const SingleRoutePage: React.FC = () => {
    const queryParams: UrlQueryString = {routeId: StringParam}
    const [query] = useQueryParams(queryParams)
    const route = getRouteFromRouteId(query.routeId)
	// const [chosenRoute] = useState(getRouteFromRouteId(query.routeId) || chooseableRoutes[0])
    
    const classes = useStyles();
    return (
        <>
            <HeaderNav
                sections={pageChoices}
            />
            <Container
                className={classes.root}
            >
                {!route ? 
                    <span> Sorry, I'm afraid that {query.routeId} is an invalid </span>
                    :
                    <RouteLibrarySection
                        route={route}
                        key={query.routeId}
                    >
                </RouteLibrarySection>
                }
                
            </Container>
            <FounderQuoteSection></FounderQuoteSection>
            <CtaSection></CtaSection>
            <FooterNav
                sections={pageChoices}
            ></FooterNav>
            <SocialShareSection></SocialShareSection>
        </>

    )
}

export default SingleRoutePage

