import React from 'react';
// import { makeStyles, Theme } from '@material-ui/core/styles';
import HeaderNav from '../HeaderNav/HeaderNav';
import FounderQuoteSection from '../FounderQuoteSection/FounderQuoteSection';
import CtaSection from '../CtaSection/CtaSection';
import FooterNav from '../FooterNav/FooterNav';
import SocialShareSection from '../SocialShareSection/SocialShareSection';
import RouteLibrarySection from '../RouteLibrarySection/RouteLibrarySection';
import chooseableRoutes from '../../assets/chooseableRoutes';
import pageChoices from '../../assets/pageChoices';




// const useStyles = makeStyles((theme: Theme) =>
//     ({
//         root: {
//             '& > *': {
//                 margin: theme.spacing(1),
//             }
//         },
//         subPageOverviewSection: {
//             marginBottom: "80px"
//         },
//         requestPricingContainer: {
//             display: "flex",
//             justifyContent: "center",
//             marginBottom: "80px"
//         },
//     }),
// );

const RouteLibraryPage: React.FC = () => {
    // const classes = useStyles();
    return (
        <>
            <HeaderNav
                sections={pageChoices}
            />
            {
                chooseableRoutes.map((item) => {
                    return (
                        <RouteLibrarySection
                            route={item}
                            key={item.routeId}
                        >
                        </RouteLibrarySection>
                    )
                })
            }
            <FounderQuoteSection></FounderQuoteSection>
            <CtaSection></CtaSection>
            <FooterNav
                sections={pageChoices}
            ></FooterNav>
            <SocialShareSection></SocialShareSection>
        </>

    )
}

export default RouteLibraryPage

