import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import HeaderNav from "../HeaderNav/HeaderNav";
import SubPageOverviewSection from "../SubPageOverviewSection/SubPageOverviewSection";
import FounderQuoteSection from "../FounderQuoteSection/FounderQuoteSection";
import CtaSection from "../CtaSection/CtaSection";
import FooterNav from "../FooterNav/FooterNav";
import SocialShareSection from "../SocialShareSection/SocialShareSection";
import FaqItem from "../FaqItem/FaqItem";
import pageChoices from "../../assets/pageChoices";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    subPageOverviewSection: {
        marginBottom: "80px",
    },
    faqContainer: {
        marginBottom: "80px",
        marginLeft: "10%",
        marginRight: "10%",
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
}));

const faqItems = [
    {
        headline: "How do you know who drives on my route?",
        content:
            "Sondayy Travelers have the Sondayy app installed on their mobile device. This allows Sondayy's algorithms to determine their normal driving behavior and their normal commute. In some cases Sondayy can also use third-party data to identify common driving habits. ",
    },

    {
        headline: "Tell me more about how this works",
        content:
            "Sondayy allows people to trade time for money. Everyone has a right to be on the road: over 175,000 people drive on I-35 in Austin every day. Our cities and counties spend billions annually on construction and maintenance to support ever growing traffic, but many current drivers would be willing to take the bus, carpool, or take a less congested route in exchange for extra cash. Sondayy provides an option for municipalities to reduce traffic by using funds to directly pay drivers to get off the road, instead of the expense (and delay) of road construction to add more lanes.",
    },

    {
        headline: "How does Sondayy work with Cities and Counties?",
        content:
            "Sondayy provides another tool to cities and counties to help them meet their public obligation to manage congestion on public roads. Instead of investing millions of tax dollars on construction new lanes or roads, Sondayy allows municipalities to quickly and directly reduce traffic on a specific route. This not only saves time, but saves cost because using resources more effectively is always more cost effective than having to build (and maintain) more lane miles.",
    },

    {
        headline: "How do you keep Travelers from cheating?",
        content:
            "Sondayy requires Travelers to have their mobile device powered on during normal commuting hours. This allows Sondayy's algorithms to confirm that they are conforming to their agreement to not drive on their normal route during prime hours. If a Traveler does violate the agreement they forfeit their earnings for the period, and in some cases may also pay a penalty charge.",
    },

    {
        headline: "What if a Traveler turns off their phone?",
        content:
            "If a Traveler turns off their phone Sondayy cannot tell whether or not they are conforming to the agreement, and therefore the same penalty as violating the agreement applies. If the Traveler has a special circumstance they can request that Sondayy reverse the penalty. ",
    },

    {
        headline: "How do you determine the prices on each route?",
        content:
            "The payout to the Traveler is determined via a dynamic pricing algorithm that is unique to each route. In general, when a municipality seeks to reduce traffic, payouts increase to attract Travelers to give up their commuting rights. When traffic is more manageable, or when many people seek to be Travelers, payouts tend to decrease",
    },

    {
        headline: "What if a Traveler just takes a different route?",
        content:
            "That is the Traveler's decision. Sondayy's commitment is to reduce traffic on the specific route that they are subscribed to. Municipalities may offer Sondayy on multiple routes to help balance traffic between them appropriately.",
    },

    {
        headline: "What if Sondayy doesn't support my normal commuting route?",
        content:
            "Let us know! We're always interested in identifying new routes where we can help reduce traffic, inequality and pollution! Please visit the Traveler page and suggest a route we should consider and we will reach out to the appropriate city or county to engaged them in discussions.",
    },

    {
        headline: "How do payouts work?",
        content:
            "Sondayy uses funding sources from the relevant city or county sponsoring that route to provide payouts to Travelers. ",
    },

    {
        headline: "Why do I keep seeing 'local community'?",
        content:
            "Sometimes global problems seem unsolvable, and that individually we have no power, but locally you can make a difference. When a municipality sponsors a route with Sondayy is provides extra cash to people who live and work in the community. It also removes cars from the road which means there is less pollution in your city or town, in addition to the small global benefit. Traffic is inherently local, and we believe the solution to traffic is local too.",
    },

    {
        headline: "How does Sondayy address economic inequality?",
        content:
            "Sondayy provides a market for Travelers to monetize their time and convenience (essentially selling it to the city). This appeals most strongly to those with less income thereby reducing the income inequality.",
    },

    {
        headline:
            "I pay my taxes! Why doesn't my city/county just build more roads?",
        content:
            "They're doing the best they can, but traffic, urban sprawl, and mobility are hard to manage. Building new roads requires space, time and money: environmental studies, engineering, material cost and and hundreds of other factors are involved, not to mention all the other services your local government is responsible for providing. Sondayy is another tool for them that can be implemented quickly and inexpensively to directly reduce traffic and thereby reduces the need for more roads. This saves local taxpayers money by reducing road maintenance and construction needs. ",
    },

    {
        headline: "I have other questions. How can I learn more about Sondayy?",
        content:
            "Happy to help! Please choose the Contact Us option in the website footer and send us a message with your question or email us at support@sondayy.com",
    },
];

const FaqPage: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <HeaderNav sections={pageChoices} />
            <SubPageOverviewSection
                className={classes.subPageOverviewSection}
                pageTitle="Frequently Asked Questions"
                subtitle="You've got questions? We've got answers ☝"
                benefits={[]}
            ></SubPageOverviewSection>
            <div className={classes.faqContainer}>
                {faqItems.map((item, index) => (
                    <FaqItem
                        headline={item.headline}
                        content={item.content}
                        key={index}
                    ></FaqItem>
                ))}
            </div>
            <FounderQuoteSection></FounderQuoteSection>
            <CtaSection></CtaSection>
            <FooterNav sections={pageChoices}></FooterNav>
            <SocialShareSection></SocialShareSection>
        </>
    );
};

export default FaqPage;
