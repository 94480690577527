import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import HeaderNav from "../HeaderNav/HeaderNav";
import OverviewSection from "../OverviewSection/OverviewSection";
import SpecificBenefit from "../SpecificBenefit/SpecificBenefit";
import ExplainerVidSection from "../ExplainerVidSection/ExplainerVidSection";
import FounderQuoteSection from "../FounderQuoteSection/FounderQuoteSection";
import CtaSection from "../CtaSection/CtaSection";
import FooterNav from "../FooterNav/FooterNav";
import SocialShareSection from "../SocialShareSection/SocialShareSection";
import { breakpoints } from "../TopLevelApp/theme";
import pageChoices from "../../assets/pageChoices";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            marginTop: "25px",
        },
        width: "auto",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-around",
        alignItems: "center",
    },
    benefitsHeadline: {
        marginBottom: "40px",
    },
    benefitsContainer: {
        width: "100%",
        marginBottom: "40px",
    },
    specificBenefitsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
        marginBottom: "40px",
        "& *": {
            width: breakpoints.sm,
            margin: "15px",
            [theme.breakpoints.down("sm")]: {
                width: "auto",
            },
        },
    },
}));

//adding comment so can trigger a commit

const BenefitsSection: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Container className={classes.benefitsContainer}>
            <Typography
                align={
                    useMediaQuery(theme.breakpoints.down("sm"))
                        ? "center"
                        : "left"
                }
                variant="h5"
                paragraph={true}
                className={classes.benefitsHeadline}
            >
                Benefit your local community
            </Typography>
            <div className={classes.specificBenefitsContainer}>
                <SpecificBenefit
                    icon={"car"}
                    headline={"Reduce Traffic and Congestion"}
                    text={
                        "Sondayy gets cars off the road. That means everyone on the road experiences less traffic, faster transit times, and less stress. Everyone benefits!"
                    }
                ></SpecificBenefit>
                <SpecificBenefit
                    icon={"flower"}
                    headline={"Reduce Carbon Dioxide and Pollutants"}
                    text={
                        "Sondayy encourages public transit use, carpooling and remote working over driving. That means cleaner air for everyone. Removing 1 car removes 4.6 tons of CO2. That’s over 60% of an average family’s CO2 output! (7.5 tons.)"
                    }
                ></SpecificBenefit>
                <SpecificBenefit
                    icon={"bulldozer"}
                    headline={"Reduce Road Construction"}
                    text={
                        "US states and counties spent $175 billion on road construction last year. Less traffic means roads last longer with less maintainance, and cities can avoid spending billions (and years of delays) on extra lanes and road expansions. Not to mention less construction preserves local habitat and wildlife 🦃."
                    }
                ></SpecificBenefit>
                <SpecificBenefit
                    icon={"unequal"}
                    headline={"Reduce Inequality"}
                    text={
                        "Everyone has an equal right to the road, but not everything else is equal. Sondayy lets drivers who have money get more time. That money goes directly to families who may need a little extra. Plus, reduced road spending means cities have more to spend on crtical services without increasing taxes."
                    }
                ></SpecificBenefit>
            </div>
        </Container>
    );
};

const HomePage: React.FC = () => {
    return (
        <>
            <HeaderNav sections={pageChoices} />
            <OverviewSection />
            <ExplainerVidSection />
            <BenefitsSection></BenefitsSection>
            <FounderQuoteSection></FounderQuoteSection>
            <CtaSection></CtaSection>
            <FooterNav sections={pageChoices}></FooterNav>
            <SocialShareSection></SocialShareSection>
        </>
    );
};

export default HomePage;
