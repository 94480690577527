//Google Maps API key: AIzaSyCoqfVoYFNY4S6S-9dCoNdF4vFnm0HfGvU (obviously not the right place to store this permanently)
//Note that documentation for the google map node module is here (looks similar to react-google-maps, but that is the OLD version and not used here)
//https://react-google-maps-api-docs.netlify.app/#section-introduction
//for googleMapsAdditionalOptions - properties are here: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions

//Make the google map a pure functional component
//use a custom hook in the container to connect to google to get map
//and directions, then pass those as props to this component
import React, { useEffect, useRef } from "react";

type mapOptions = {
    clickableIcons?: boolean;
    fullscreenControl?: boolean;
    mapTypeControl?: boolean;
    streetViewControl?: boolean;
    controlSize?: number;
    center: { lat: number; lng: number }; //default to Austin for center
    zoom: number;
};

export type RouteDisplayMapProps = {
    routeId: string; //use empty string if shouldn't display route
    directionsData?: {} | null;
    mapOptions: mapOptions;
    height: string;
    width?: string;
};

const RouteDisplayMap: React.FC<RouteDisplayMapProps> = (
    props: RouteDisplayMapProps
) => {
    let map: { current: any } = useRef(null);
    let directionsRenderer: { current: any } = useRef(null);
    const { height, width } = props;
    useEffect(() => {
        const initMap = () => {
            // @ts-ignore: google object loaded into window.google by the google maps api script being imported
            map.current = new google.maps.Map(
                document.getElementById(`map-${props.routeId}`) as HTMLElement,
                { ...props.mapOptions }
            );
            // @ts-ignore: google object loaded into window.google by the google maps api script being imported
            directionsRenderer.current = new google.maps.DirectionsRenderer();
            directionsRenderer.current.setMap(map.current);
        };
        initMap();
    }, [props.mapOptions, props.routeId]);
    useEffect(
        () => directionsRenderer.current.setDirections(props.directionsData),
        [props.directionsData]
    );

    return (
        <div
            id={`map-${props.routeId}`}
            style={{ height: height, width: width || "inherit" }}
        ></div>
    );
};

export default React.memo(RouteDisplayMap);
