import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import HeaderNav from '../HeaderNav/HeaderNav';
import SubPageOverviewSection from '../SubPageOverviewSection/SubPageOverviewSection';
import ContactUsSection from '../ContactUsSection/ContactUsSection';
import FounderQuoteSection from '../FounderQuoteSection/FounderQuoteSection';
import CtaSection from '../CtaSection/CtaSection';
import FooterNav from '../FooterNav/FooterNav';
import SocialShareSection from '../SocialShareSection/SocialShareSection';
import pageChoices from '../../assets/pageChoices';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            }
        },
        subPageOverviewSection: {
            marginBottom: "80px"
        },
        contactUsSection: {
            marginBottom: "80px"
        }
    }),
);

const ContactUsPage: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <HeaderNav
                sections={pageChoices}
            />
            <SubPageOverviewSection
                className={classes.subPageOverviewSection}
                pageTitle="Contact Us"
                subtitle="We welcome any questions, input or feedback 🙋‍♀️"
            ></SubPageOverviewSection>
            <div className={classes.contactUsSection}>
                <ContactUsSection />
            </div>
            <FounderQuoteSection></FounderQuoteSection>
            <CtaSection></CtaSection>
            <FooterNav
                sections={pageChoices}
            ></FooterNav>
            <SocialShareSection></SocialShareSection>
        </>

    )
}

export default ContactUsPage

