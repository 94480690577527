import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Link } from "react-router-dom";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography';
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as LogoGraphic } from '../../assets/logoGraphic.svg';
import { ReactComponent as LogoLettering } from '../../assets/logoLettering.svg';
import { ReactComponent as LogoTagLine } from '../../assets/logoTagLine.svg';
import { PageChoice} from "../../sharedTypes/sharedTypes";



const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF"
        },
        combinedLogo: {
            margin: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            [theme.breakpoints.up('md')]: {
                display: "none"
            },
        },
        menuButton: {
            [theme.breakpoints.up('md')]: {
                display: "none"
            },
        },
        sectionLabel: {
            [theme.breakpoints.down('md')]: {
                display: "none"
            },
            [theme.breakpoints.up('md')]: {
                display: "unset"
            },
        },
        drawerPaper: {
            width: "75%",
            background: "#18A0FB",
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down('sm')]: {
                width: "90%"
            },
        },
        drawerList: {
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around"
        },
        drawerItem: {
            color: "white"
        }

    })
);


type headerNavProps = {
    sections: PageChoice[]
}

const HideOnScroll = (props: { children: React.ReactElement, window?: () => Window }) => {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });
    return (
        <Slide
            appear={false}
            direction="down"
            in={!trigger}
        >
            {children}
        </Slide>
    )
}

// const DrawerItem: React.FC = (props) => {
//     const {label} = props
//     return (
//         <ListItem button key={label || props}>
//         <ListItemText primary={label || props}></ListItemText>
//     </ListItem>
//     )
// }

const HeaderNav: React.FC<headerNavProps> = (props) => {
    const classes = useStyles();
    const [isDrawerOpen, setDrawerOpenStatus] = useState(false)

    return (
        <>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar position="sticky" elevation={1}>
                    <Toolbar className={classes.root}>
                        <SvgIcon
                            viewBox="0 0 24 32"                        
                        ><LogoGraphic /></SvgIcon>
                        {props.sections.map((item) =>
                            <Typography
                                className={classes.sectionLabel}
                                color="primary"
                                variant="body2"
                                align="center"
                                key={item.label}
                            >
                                <Link to={item.src} style={{textDecoration: "none", color: "inherit"}}>{item.label}</Link>
                            </Typography>)
                        }
                        <div className={classes.combinedLogo}>
                            <LogoLettering />
                            <LogoTagLine />
                        </div>
                        <IconButton
                            edge="end"
                            className={classes.menuButton}
                            onClick={() => setDrawerOpenStatus(!isDrawerOpen)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <SwipeableDrawer
                classes={{ paper: classes.drawerPaper }}
                anchor="left"
                open={isDrawerOpen}
                onClose={() => setDrawerOpenStatus(false)}
                onOpen={() => setDrawerOpenStatus(false)}
            >
                <List
                    className={classes.drawerList}
                >
                    {props.sections.map((item: PageChoice) =>
                        <ListItem
                            className={classes.drawerItem}
                            button
                            key={item.label}
                        >
                            <ListItemText>
                                <Typography
                                    color="inherit"
                                    variant="h4"
                                    align="center"
                                >
                                    <Link to={item.src} style={{textDecoration: "none", color: "inherit"}}>{item.label}</Link>
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>
            </SwipeableDrawer>
        </>

    )
}

export default HeaderNav