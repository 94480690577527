import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import photo from "../../assets/old_thunderbird.jpg";


const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
            display: "flex",
            flexWrap: "nowrap",
            maxWidth: "100%",
            backgroundColor: "#F5F5F5",
            paddingBottom: "80px",
        },
        text: {
            flex: 1
        },
        headline: {
            fontWeight: "bold"
        },
        photo: {
            flex: 1,
            maxWidth: "500px",
            objectFit: "cover",
            //have to add this, otherwise BOTH pictures are hidden when exactly at md breakpoint
            [theme.breakpoints.down('lg')]: {
                display: "none"
            },
            [theme.breakpoints.only('lg')]: {
                display: "block"
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: "1000px"
            },
        },
        smallScreenPhoto: {
            flex: 1,
            maxWidth: "100%",
            objectFit: "cover",
            [theme.breakpoints.up('lg')]: {
                display: "none"
            },
        },
        pullQuote: {
            display: "flex",
            alignItems: "baseline",
            flexWrap: "nowrap",
            justifyContent: "flex-end",
            '& :first-child': {
                marginRight: "8px"
            }
        },
    }),
);

const FounderQuoteSection: React.FC = () => {
    const classes = useStyles();

    return (
        <Container
            maxWidth="xl"
            className={classes.root}
        >
            <div className="text">
                <Typography
                    className={classes.headline}
                    variant="h5"
                    paragraph={true}
                    align="center">
                    Sondayy offers a real, actionable way to solve the traffic problem for everyone.
                </Typography>
                <Typography
                    variant="body1"
                    paragraph={true}>
                    Traffic is a plague of the modern world making many of our greatest cities almost unlivable. We spend 18 days a year imprisoned in our cars, spitting out air pollution and listening to podcasts. It may feel inevitable, but it’s not.
                </Typography>
                <img
                    src={photo}
                    className={classes.smallScreenPhoto}
                    alt="Old Thunderbird Car"
                >
                </img>
                <Typography
                    variant="body1"
                    paragraph={true}>
                    Sondayy is a true solution to using roads effectively, while at the same time providing major economic benefits in your local community. If you’re frustrated and want a world with less traffic, more walkability, and cleaner air... this is your opportunity to take action.
                </Typography>
                <div
                    className={classes.pullQuote}
                >
                    <Typography
                        variant="body2"
                        paragraph={true}
                        align="right"
                    >
                        - founder
                </Typography>
                    <Typography variant="h6">
                        Michael J. Bruns
                </Typography>


                </div>
            </div>
            <img
                src={photo}
                className={classes.photo}
                alt="Old Thunderbird Car"
            >
            </img>
        </Container>

    )
}

export default FounderQuoteSection

