import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import AddIcon from '@material-ui/icons/Add'; //use as expansion icon
// import { breakpoints } from '../TopLevelApp/theme';
import Typography from '@material-ui/core/Typography';



const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			'& > *': {
				margin: theme.spacing(1),
			},
			//   minWidth: breakpoints.sm,
			maxWidth: "100%"
		},
		headline: {
			flexGrow: 2,
			display: "flex",
			flexDirection: "row-reverse",
			justifyContent: "space-around",
			fontWeight: "bold"
		},
		spacer: {
			minWidth: "2rem"
		},
		content: {
			//play with the padding/alignment here later if you need to
		}
	}),
);

type faqItemProps = {
	headline: string,
	content: string
}


const FaqItem: React.FC<faqItemProps> = (props) => {
	const classes = useStyles();
	return (
		<div>
			<ExpansionPanel
				className={classes.root}
				elevation={0}
			>
				<ExpansionPanelSummary className={classes.headline}
					expandIcon={<AddIcon />}
					aria-controls={props.content}
				>
					<span className={classes.spacer}></span>
					<Typography variant="body1">{props.headline}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.content}>
					<Typography variant="body2">{props.content}</Typography>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>

	)
}

export default FaqItem

