import React, { useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DefaultButton from '../DefaultButton/DefaultButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { useQueryParams, StringParam } from 'use-query-params';
import {UrlQueryString} from '../../sharedTypes/sharedTypes'

type UserType = "driver" | "traveler";

type ExtraQuestionsFormProps = {
	open: boolean,
	closeModalFunction: (newState: boolean) => void,
	formValues: {[key: string]: any},
    setFormValues: React.Dispatch<React.SetStateAction<{[key: string]: any}>>,
	driverOrTraveler?: UserType,	
}

type DriverOrTravelerQuestionsProps = {
	formValues: {[key: string]: any},
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const DriverQuestions: React.FC<DriverOrTravelerQuestionsProps> = (props) => {
	const requiredTimeSavingsOptions = [
		{ value: '5min', label: "5 minutes" }, 
		{ value: '10min', label: "10 minutes" }, 
		{ value: '20min', label: "20 minutes" }, 
		{ value: '30min', label: "30 minutes" }
	]
	return (
		<>
			<Typography
				variant="subtitle1"
				paragraph={true}
				align="center">
				How much is a faster commute worth to you?
			</Typography>
			<TextField
				id="commuteValue"
				fullWidth
				variant="outlined"
				placeholder="$20 a month? $50 a month?"
				size="small"
				value={props.formValues["commuteValue"] || ""}
				onChange={props.handleChange}
			>
			</TextField>
			<Typography
				variant="subtitle1"
				paragraph={true}
				align="center">
				How much faster does your commute need to be to be worth paying for?
			</Typography>
			<TextField
				id="requiredTimeSavings"
				name="requiredTimeSavings"
				select={true}
				value={props.formValues["requiredTimeSavings"] || requiredTimeSavingsOptions[0].value}
				fullWidth
				variant="outlined"
				size="small"
				onChange={props.handleChange}
			>
				{requiredTimeSavingsOptions.map((item) =>
					<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
				)}
			</TextField>
		</>
	)
}

const TravelerQuestions: React.FC<DriverOrTravelerQuestionsProps> = (props) => {
	const alternativeToDrivingOptions = [
		{value: "workRemotely", label: "Work remotely"}, 
		{value: "carpool", label: "Carpool"}, 
		{value: "takeAnotherRoute", label: "Take another route"}, 
		{value: "bike", label: "Bike"}, 
		{value: "walk", label: "Walk"}, 
		{value: "train", label: "Train"}, 
		{value: "bus", label: "Bus"}, 
		{value: "other", label: "Other"}
]
	return (
		<>
			<Typography
				variant="subtitle1"
				paragraph={false}
				align="center">
				What alternative to driving are you considering?
			</Typography>
			<TextField
				id="alternativeToDriving"
				name="alternativeToDriving"
				select={true}
				value={props.formValues["alternativeToDriving"] || alternativeToDrivingOptions[0].value}
				fullWidth
				variant="outlined"
				size="small"
				onChange={props.handleChange}
			>
				{alternativeToDrivingOptions.map((item) =>
					<MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
				)}
			</TextField>
			<Typography
				variant="subtitle1"
				paragraph={false}
				align="center">
				How much per month would you need to make it worth it to switch to that alternative?
			</Typography>
			<TextField
				id="commuteValue"
				fullWidth
				variant="outlined"
				placeholder="$10 a month? $30 a month?"
				size="small"
				value={props.formValues["commuteValue"] || ""}
				onChange={props.handleChange}
			>
			</TextField>
		</>
	)
}

const ExtraQuestionsForm: React.FC<ExtraQuestionsFormProps> = (props) => {

	const theme = useTheme();
	//state
	let history = useHistory();
	const queryParams: UrlQueryString = {routeId: StringParam}
    const [query] = useQueryParams(queryParams)

	const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const {id, name, value} = e.target;
        props.setFormValues(formValues => ({...formValues, [id || name]: value}))
    }, [props])

	const handleSubmit = async () => {

		//TODO: Add some failure handling here later
		//Adding this comment to force a rebuild. Nothing is ever simple
		const SONDAYY_API_URL = process.env.REACT_APP_SONDAYY_API_URL;

		const defaultRoute = "rid-i35atx"

		const prospect = {
			...props.formValues, 
			normalRoute: query.routeId || defaultRoute,
			driverOrTraveler: props.driverOrTraveler
		}

		const response = await fetch(`${SONDAYY_API_URL}/prospects/create`, {
			method: 'POST',
			body: JSON.stringify({payload: prospect}),
			headers: {
				'Content-Type': 'application/json'
			}
		})

		console.log(response)

		if (!!response) { props.closeModalFunction(!props.open)
			setTimeout(() => history.push('/routenotready'), 500)
		}
	}

    return (
		<Dialog
			fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
			open={props.open}
			onClose={() => props.closeModalFunction(!props.open)}
			aria-labelledby="Just curious"
			fullWidth={true}
			maxWidth="md"
		>
			<DialogTitle id="Just curious">
				<Typography
					variant="h4"
					paragraph={false}
					align="center">
					Just Curious...
                </Typography>
			</DialogTitle>
			<DialogContent
				key="content"
			>
				<DialogContentText>
				<Typography
					variant="h5"
					paragraph={true}
					align="center">
					Your answers won't affect your pricing
                </Typography>
          	</DialogContentText>
			  {
				  props.driverOrTraveler === "driver" ? 
				  <DriverQuestions 
				  	key="driverQuestions" 
				  	formValues={props.formValues}
				  	handleChange={handleChange}
				  ></DriverQuestions> :
				  <TravelerQuestions 
				  	key="travelerQuestions" 
				  	formValues={props.formValues}
				  	handleChange={handleChange}
				  ></TravelerQuestions>
			  }			  
			</DialogContent>
			<DialogActions>
				<DefaultButton 
					color="primary"
					onClick={() => handleSubmit()}
				>
					Continue
          		</DefaultButton>
			</DialogActions>
		</Dialog>
    )
}

export default ExtraQuestionsForm