import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import HeaderNav from "../HeaderNav/HeaderNav";
import SubPageOverviewSection, {
    subPageOverviewSectionProps,
} from "../SubPageOverviewSection/SubPageOverviewSection";
import RequestPricingForm from "../RequestPricingForm/RequestPricingForm";
import FounderQuoteSection from "../FounderQuoteSection/FounderQuoteSection";
import CtaSection from "../CtaSection/CtaSection";
import FooterNav from "../FooterNav/FooterNav";
import SocialShareSection from "../SocialShareSection/SocialShareSection";
import RouteChooserSection from "../RouteChooserSection/RouteChooserSection";
import pageChoices from "../../assets/pageChoices";
import ExtraQuestions from "../ExtraQuestions/ExtraQuestionsForm";
import ContactUsSection from "../ContactUsSection/ContactUsSection";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    subPageOverviewSection: {
        marginBottom: "80px",
    },
    requestPricingContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "80px",
    },
}));

type DriversAndTravelersPageProps = {
    driverOrTraveler: "driver" | "traveler";
};

const DriversAndTravelersPage: React.FC<DriversAndTravelersPageProps> = (
    props: DriversAndTravelersPageProps
) => {
    const classes = useStyles();
    //state
    const [showExtraQuestions, setShowExtraQuestions] = useState(false);
    const [formValues, setFormValues] = useState<{ [key: string]: any }>({});

    const driverOverviewProps: subPageOverviewSectionProps = {
        pageTitle: "Greener than Electric Cars ☘️",
        subtitle:
            "Sondayy is a totally new tool for urban planners that lets you directly influence how many cars are using your major roadways",
        image: "driver",
        benefits: [
            {
                headline: "Improve traffic now, not years from now",
                headlineAlignment: "center",
                text: "Sondayy uses sophisticated algorithms to identify who is driving on your roadways and offers some of them a financial incentive to get off the road. This reduces traffic for those who value driving most.",
            },
            {
                headline: "Without the delays and costs of road construction",
                headlineAlignment: "center",
                text: "Sondayy can be launched on any roadway within 90 days. No permitting, no roadwork, and no major capital outlays are involved. Costs can be adjusted to needs and budgets. You can even temporarily reduce congestion for short-term events like road accidents or special events.",
            },
            {
                headline: "Increase your appeal for federal grants",
                headlineAlignment: "center",
                text: "As part of the new infrastructure bill federal entities are looking to quickly fund projects that align with their goals. Not driving at all is as green as it gets 🌿 and Sondayy payments reduce income inequality while keeping money within your local community. Plus 90 days definitely counts as shovel-ready!",
            },
            {
                headline: "...and watch your citizens take notice!",
                headlineAlignment: "center",
                text: "The work to make our cities liveable and beautiful isn’t always appreciated, but everyone loves less traffic, and lower costs. Plus your citizens definitely will notice money in their pockets from shifting away from private cars!",
            },
        ],
    };

    const travelerOverviewProps: subPageOverviewSectionProps = {
        pageTitle: "Cash in as a Sondayy Traveler",
        subtitle:
            "Sondayy pays Travelers to find an alternative to their normal driving commute",
        image: "traveler",
        benefits: [
            {
                headline: "Trade time in the car for cash in your pocket",
                headlineAlignment: "center",
                text: "Your time is valuable, and Sondayy wants to pay you for it! If you can take public transit 🚂, carpool, take a different route, adjust your work times, or work remotely then Sondayy now makes those options even more attractive.",
            },
            {
                headline: "Support your local environment and community",
                headlineAlignment: "center",
                text: "You’re not just cashing a check here: by driving less you’re measurably reducing air pollution, saving your city money, and supporting public transportation and walkable communities 👟. Don’t forget reducing traffic for everyone too, so thanks 🤗! ",
            },
            {
                headline: "We can even help you carpool",
                headlineAlignment: "center",
                text: "Sondayy can use our data to identify other drivers with almost the exact same commute as you... so why not ride together? Same commute, same convenience, but you get paid! ",
            },
            {
                headline: "...but we do enforce the rules ",
                headlineAlignment: "center",
                text: "Just don’t drive when you said you wouldn’t and we’re all good. We do charge a penalty if we have reason to think you’re on the road when you promised you wouldn’t be - totally not cool.",
            },
        ],
    };

    const overviewProps =
        props.driverOrTraveler === "driver"
            ? driverOverviewProps
            : travelerOverviewProps;

    return (
        <>
            <HeaderNav sections={pageChoices} />
            <SubPageOverviewSection
                className={classes.subPageOverviewSection}
                {...overviewProps}
            ></SubPageOverviewSection>
            {props.driverOrTraveler === "traveler" ? (
                <>
                    <RouteChooserSection
                        driverOrTraveler={props.driverOrTraveler}
                    ></RouteChooserSection>
                    <Typography variant="h4" paragraph={true} align="center">
                        {`Let's see the payout for that`}
                    </Typography>
                    <div className={classes.requestPricingContainer}>
                        <RequestPricingForm
                            pricingOrPayout={
                                props.driverOrTraveler === "traveler"
                                    ? "Payout"
                                    : "Pricing"
                            }
                            route="the West Loop"
                            stateHandlerForExtraQuestionsModal={
                                setShowExtraQuestions
                            }
                            formValues={formValues}
                            setFormValues={setFormValues}
                        ></RequestPricingForm>
                    </div>
                    <ExtraQuestions
                        open={showExtraQuestions}
                        closeModalFunction={setShowExtraQuestions}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        driverOrTraveler={props.driverOrTraveler}
                    ></ExtraQuestions>
                </>
            ) : (
                <>
                    <Typography variant="h3" paragraph={true} align="center">
                        Contact us about improving usage of your roadways
                    </Typography>
                    <ContactUsSection />
                </>
            )}

            <FounderQuoteSection></FounderQuoteSection>
            <CtaSection></CtaSection>
            <FooterNav sections={pageChoices}></FooterNav>
            <SocialShareSection></SocialShareSection>
        </>
    );
};

export default DriversAndTravelersPage;
