import { useState, useEffect } from "react";
import { RouteChoice } from "../sharedTypes/sharedTypes";

//TODO: On the route library you're looping through the loadscript to get google too many times and your routes aren't actually showing except the
//first map that is shown.

const useGetRouteData = (
    requestedRoute: RouteChoice,
    googleMapsApiLoaded: boolean
) => {
    //initialize state IN the hook for the routeData received
    const [routeData, setRouteData] = useState<{} | null>(null);
    let directionsSvc: { route: Function } | null = null;
    // @ts-ignore: google object loaded into window.google by the google maps api script being imported
    if (googleMapsApiLoaded && !!window.google) {
        // @ts-ignore: google object loaded into window.google by the google maps api script being imported
        directionsSvc = new google.maps.DirectionsService();
    }
    // const directionsSvc = new google.maps.DirectionsService();
    useEffect(() => {
        if (googleMapsApiLoaded && directionsSvc) {
            directionsSvc.route(
                {
                    origin: requestedRoute.origin,
                    destination: requestedRoute.destination,
                    travelMode: "DRIVING",
                },
                (data: any, status: string) => {
                    status === "OK" ? setRouteData(data) : setRouteData(null);
                }
            );
        }
    }, [
        requestedRoute.routeId,
        googleMapsApiLoaded,
        directionsSvc,
        requestedRoute,
    ]);
    return routeData;
};

export default useGetRouteData;
