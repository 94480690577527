import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as LogoGraphic } from '../../assets/logoGraphic.svg';
import { ReactComponent as LogoLettering } from '../../assets/logoLettering.svg';
import { ReactComponent as LogoTagLine } from '../../assets/logoTagLine.svg';
import { PageChoice} from "../../sharedTypes/sharedTypes";

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            borderBottom: "1px solid greys",
        },
        combinedLogo: {
            margin: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1
        },
        sectionsContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexGrow: 2,
            [theme.breakpoints.down('sm')]: {
                display: "none"
            },
        }
    })
);

type headerNavProps = {
    sections: PageChoice[]
}

const FooterNav: React.FC<headerNavProps> = (props) => {
    const classes = useStyles();
    const allSections = [...props.sections, {label: "Contact Us", src: "/contactus"}];
    return (
        <AppBar position="sticky" elevation={0}>
            <Toolbar className={classes.root}>
                <div className={classes.sectionsContainer}>
                    {allSections.map((item: PageChoice, index) => {
                        if (index <= allSections.length / 2 - 1) {
                            return (
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                    align="center"
                                    key={item.label}
                                >
                                    <Link to={item.src} style={{textDecoration: "none", color: "inherit"}}>{item.label}</Link>
                                </Typography>
                            )
                        }
                        return null
                    }
                    )
                    }
                </div>
                <div className={classes.combinedLogo}>
                    <LogoGraphic />
                    <LogoLettering />
                    <LogoTagLine />
                </div>
                <div className={classes.sectionsContainer}>
                    {allSections.map((item: PageChoice, index) => {
                        if (index > allSections.length / 2 - 1) {
                            return (
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                    align="center"
                                    key={item.label}
                                >
                                    <Link to={item.src} style={{textDecoration: "none", color: "inherit"}}>{item.label}</Link>
                                </Typography>
                            )
                        }
                        return null
                    }
                    )
                    }
                </div>
            </Toolbar>
        </AppBar>

    )
}

export default FooterNav