//Google Maps API key: AIzaSyCoqfVoYFNY4S6S-9dCoNdF4vFnm0HfGvU (obviously not the right place to store this permanently)
//Note that documentation for the google map node module is here (looks similar to react-google-maps, but that is the OLD version and not used here) 
//https://react-google-maps-api-docs.netlify.app/#section-introduction
//for googleMapsAdditionalOptions - properties are here: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions

// https://maps.googleapis.com/maps/api/js?key=AIzaSyCoqfVoYFNY4S6S-9dCoNdF4vFnm0HfGvU&libraries=&v=weekly

import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import RouteDisplayMap from '../RouteDisplayMap/RouteDisplayMap';
import DefaultButton from '../DefaultButton/DefaultButton';
import { RouteChoice} from '../../sharedTypes/sharedTypes';
// import useScriptv2 from '../../hooks/useScriptv2';
import useGetRouteData from '../../hooksAndHelpers/useGetRouteData';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			'& > *': {
				margin: theme.spacing(1),
			},
			width: "100%",
			marginBottom: "80px",
			display: "flex",
			flexWrap: "wrap",
			flexDirection: "row",
			justifyContent: "space-between",
		},
		mapContainer: {
			width: "100%",
			height: "100%",
			maxWidth: "500px",
			[theme.breakpoints.down('sm')]: {
				minHeight: "unset",
				height: "80vh",
			},
		},
		contentContainer: {
			marginTop: "20px",
			display: "flex",
			flexDirection: "column",
			maxWidth: "500px",
			[theme.breakpoints.down('sm')]: {
				minHeight: "unset",
				marginBottom: "40px"
			},
		},
		buttonContainer: {
			display: "flex",
			flexDirection: "row",
			alignSelf: "flex-start",
			alignItems: "center",
			[theme.breakpoints.down('sm')]: {
				alignSelf: "center",
				flexDirection: "column",
			},
		}
	}),
);

// const apiKey = "AIzaSyCoqfVoYFNY4S6S-9dCoNdF4vFnm0HfGvU"

const googleMapsAdditionalOptions = {
	clickableIcons: false,
	fullscreenControl: false,
	mapTypeControl: false,
	streetViewControl: false,
	controlSize: 24,
	center: { lat: 30.292, lng: -97.729 }, //default to Austin for center
	zoom: 10 //default to 10 for zoom (city-level zoom)
}

const RouteLibrarySection: React.FC<{route: RouteChoice}> = (props: {route: RouteChoice}) => {
	const classes = useStyles();
	const theme = useTheme();
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const { route } = props;
	//state here
	const googleMapsApiLoaded = true;
	//moved the google maps API to the top level index.html since need it for basically evey page anyway
	// const [ googleMapsApiLoaded ] = useScriptv2(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=&v=weekly`)
	const routeData = useGetRouteData(route, googleMapsApiLoaded)
	//end state

	if (!googleMapsApiLoaded) {
		return (
			<span>Google Maps API not loaded</span>
		)
	}
	return (
		<Container className={classes.root}>
			<div className={classes.mapContainer}>
				<RouteDisplayMap
					routeId={route.routeId}
					directionsData={route.cachedRouteData || routeData}
					mapOptions={googleMapsAdditionalOptions}
					height={smallScreen ? '80vh' : "500px"}
				/>
			</div>
			<div className={classes.contentContainer}>
				<Typography
					variant="h4"
					paragraph={true}
				>
					{props.route.label}
				</Typography>
				<Typography
					variant="body1"
					paragraph={true}
				>
					{props.route.description}
				</Typography>
				<Typography
					variant="body1"
					paragraph={true}
				>
					{`Most popular Traveler Alternative: ${props.route.mostPopularAlternative}`}
				</Typography>
				<div className={classes.buttonContainer}>
					<DefaultButton>
						<Link to={`/driverspage?routeId=${route.routeId}`} style={{textDecoration: "none", color: "inherit"}}>Sign Up as Driver</Link>
                    </DefaultButton>
					<DefaultButton
						variant="outlined"
						size="small"
					>
						<Link to={`/travelerspage?routeId=${route.routeId}`} style={{textDecoration: "none", color: "inherit"}}>Sign Up as Traveler</Link>
                    </DefaultButton>
				</div>
			</div>
		</Container>
	)


}

export default RouteLibrarySection

// label: string,
// 	origin: string,
// 	destination: string,
// 	routeId: string,
// 	cachedRouteData?: {} | null