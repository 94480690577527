import { RouteChoice} from '../sharedTypes/sharedTypes';
import { i35Route, westLoopRoute } from './cachedRoutes';


const chooseableRoutes: RouteChoice[] = [
    {
        routeId: "rid-i35atx", 
        label: "I-35 in Austin", 
        origin: "4309 1/2 Avenue F, Austin, TX 78751", 
        destination: "1400 Guadalupe St, Austin, TX 78703", 
        description: "It doesn't matter whether you're high or low (deck), I-35 can make you want to pull your hair out no matter how much you enjoy the city of Austin. Terrible traffic!",
        mostPopularAlternative: "Carpool",
        cachedRouteData: i35Route
    },
    {
        routeId: "rid-wlhtx", 
        label: "The West Loop in Houston", 
        origin: "Southwest Houston, Houston, TX 77096", 
        destination: "2800-2900 N Loop W, Houston, TX 77092", 
        description: "Growing up in Houston in the 90s, you knew that one of the most important freeways to avoid was the West Loop. A lot about the city of Houston has changed... but not that!",
        mostPopularAlternative: "Driving during non-rush hour",
        cachedRouteData: westLoopRoute
    },
    {
        routeId: "rid-i95dc",
        label: "I-95 in DC", 
        origin: "I-95, Woodbridge, VA 22191", 
        destination: "I-395, Washington, DC 20024", 
        description: "When I first got to DC I found the roads confusing. After a few years as a pedestrian, I realized that L'Enfant's design was elegant. Then I started driving and a realized, \"Nope, it's a disaster!\"",
        mostPopularAlternative: "Public transportation",
    },
    {
        routeId: "rid-i495eastbeltwaydc",
        label: "The East Beltway in DC", 
        origin: "I-95, Oxon Hill, MD 20745", 
        destination: "I-95, Greenbelt, MD 20770", 
        description: "Oh no... please... I don't want to go to IKEA!",
        mostPopularAlternative: "Park & Ride",
    },
    {
        routeId: "rid-i95alexhamiltonnyc", 
        label: "Alexander Hamilton Bridge in NYC", 
        origin: "George Washington Bridge, New York, NY 10033", 
        destination: "I-95, The Bronx, NY 10460", 
        description: "Alexander Hamilton Bridge and the Cross Bronx Expressway. What fun.",
        mostPopularAlternative: "Train"
    },
    {
        routeId: "rid-i405sandiegofwyla", 
        label: "The San Diego Freeway in LA", 
        origin: "I-405, Irvine, CA 92614", 
        destination: "I-405, Lennox, CA 90304", 
        description: "Sunny LA! At least the weather is beautiful while you sit in traffic.",
        mostPopularAlternative: "train"
    },
    {
        routeId: "rid-i90chitown", 
        label: "I-90 in Chicago", 
        origin: "Jane Addams Memorial Tollway, Elgin, IL 60120", 
        destination: "I-90, Gary, IN 46402", 
        description: "The Chicago Skyway, averaging 65% slower than the posted speed limit. The windy city doesn't mind wind in your hair from driving too fast...",
        mostPopularAlternative: "train"
    },
    {
        routeId: "rid-i80sf", 
        label: "I-80 in San Francisco", 
        origin: "298 Vermont St, San Francisco, CA 94103", 
        destination: "Dwight D. Eisenhower Hwy, Oakland, CA 94607", 
        description: "The Bay Bridge is a marvel of engineering, and being stuck on it gives you time to ponder how Oakland seems so close, but is actually so far away.",
        mostPopularAlternative: "Train"
    },
    {
        routeId: "rid-i93beantown", 
        label: "I-93 in Boston", 
        origin: "US-1, Quincy, MA 02169", 
        destination: "US-1, Boston, MA 02111", 
        description: "Does driving in from Canton make you want to throw tea into the haw-burh? It's ok - you're in good company.",
        mostPopularAlternative: "Train"
    },
    {
        routeId: "rid-i85atl", 
        label: "I-85 in Atlanta", 
        origin: "I-85, Atlanta, GA 30340", 
        destination: "I-85, Atlanta, GA 30303", 
        description: "Keep that A/C going in Hotlanta - you're going to be sitting in traffic for a while!",
        mostPopularAlternative: "Park & Ride"
    },
  ]

  export default chooseableRoutes