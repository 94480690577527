import { PageChoice } from "../sharedTypes/sharedTypes";

const pageChoices: PageChoice[] = [
    { label: "How it Works 🎓", src: "/" },
    { label: "Counties and Municipalities 🏛️", src: "/driverspage" },
    { label: "Traveler Sign Up 💰", src: "/travelerspage" },
    { label: "Where we Operate 🗺️", src: "/routelibrary" },
    { label: "Skeptical? 🙄", src: "/faqpage" },
];

//Note that the Contact Us section appears only in the footer and is added in with the FooterNav component
//I don't want it to be too prominent where people use it all the time
export default pageChoices;
