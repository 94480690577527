import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {InputLabelProps} from '@material-ui/core/InputLabel'; //need this for the type definition
import Typography from '@material-ui/core/Typography';
import DefaultButton from '../DefaultButton/DefaultButton';
// import chooseableRoutes from '../../assets/chooseableRoutes';
import { useQueryParams, StringParam } from 'use-query-params';
import {UrlQueryString} from '../../sharedTypes/sharedTypes'
import getRouteFromRouteId from '../../hooksAndHelpers/getRouteFromRouteId';


//TODO: Add Validation and error handling

const useStyles = makeStyles((theme: Theme) => 
(
    {
        root: {
            padding: "20px",
            border: "1px solid black",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            maxWidth: "500px",
            [theme.breakpoints.down('sm')]: {
                border: "none"
            },
        },
        nothing: {},
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                width: "100%"
            },
        },
        headline: {
            flexBasis: "100%",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold"
        },
        fullWidth: {
            flexBasis: "100%"
        },
        postalCode: {
            marginRight: "auto"
        },
        buttonContainer: {
            flexBasis: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "25px"
        },
        small: {
            fontSize: "5px"
        }

    })
);

type RequestPricingFormProps = {
    stateHandlerForExtraQuestionsModal: (newState: boolean) => void, 
    formValues: {[key: string]: any},
    setFormValues: React.Dispatch<React.SetStateAction<{[key: string]: any}>>,
    route?: string,
    pricingOrPayout?: "Pricing" | "Payout",
}

const RequestPricingForm: React.FC<RequestPricingFormProps> = (props) => {

    const classes = useStyles();
    //state
    const queryParams: UrlQueryString = {routeId: StringParam}
    const [ query ] = useQueryParams(queryParams)
    // const [chosenRoute] = useState(getRouteFromRouteId(query.routeId) || chooseableRoutes[0])
    const routeChoice = getRouteFromRouteId(query.routeId) || {label: "your route"}
    //end state
    
    type StyleProps = keyof typeof classes

    type Field = {
        autoComplete?: string,
        id: string,
        label: string,
        className?: StyleProps
        InputLabelProps?: InputLabelProps
    }

    const fields: Field[] = [
        {autoComplete: "given-name", id: "firstName", label: "First Name" },
        {autoComplete: "family-name", id: "lastName", label: "Last Name" },
        {autoComplete: "given-name", id: "postalCode", label: "Zip Code", className: "postalCode" },
        {id: "email", label: "Email" },
        {id: "amCommuteTime", label: "When do you normally leave for Work?", className: "fullWidth", InputLabelProps: {shrink: true} },
        {id: "pmCommuteTime", label: "When do you normally leave for Home?", className: "fullWidth", InputLabelProps: {shrink: true} },
    ]

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target;
        props.setFormValues(formValues => ({...formValues, [id]: value}))
    }

    const createTextField = (id: string, label: string, className: StyleProps, InputLabelProps: Partial<InputLabelProps>, index: number) => {
        return (
            <TextField
            className={`${classes.textField} ${classes[className] || ""}`}
            InputLabelProps={InputLabelProps}
            id={id}
            label={label}
            value={props.formValues[id] || ""}
            onChange={handleChange}
            key={index}
        />    
        )
    }

  return (
    <div className={classes.root}>
        <Typography 
            className={classes.headline}
            align="center"
            variant="h6" 
        >
            See {props.pricingOrPayout} for {routeChoice.label}
        </Typography>
        {fields.map((item, index) => {
            const {id, label, className = "nothing", InputLabelProps} = item;
            return (
                createTextField(id, label, className, InputLabelProps || {}, index)
            )
        })}
        <div
            className={classes.buttonContainer}
        >
            <DefaultButton
                onClick={() => props.stateHandlerForExtraQuestionsModal(true)}
            >
                See {props.pricingOrPayout}
            </DefaultButton>
        </div>

    </div>
  );
}

export default RequestPricingForm