import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { breakpoints } from "../TopLevelApp/theme";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Facebook } from "../../assets/facebookSocialShareButton.svg";
import { ReactComponent as Twitter } from "../../assets/twitterSocialShareButton.svg";
import { ReactComponent as Instagram } from "../../assets/instagramSocialShareButton.svg";
import { ReactComponent as Linkedin } from "../../assets/linkedinSocialShareButton.svg";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        paddingTop: "40px",
        paddingBottom: "40px",
    },
    buttonContainer: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        minWidth: breakpoints.sm,
    },
    socialButton: {
        marginLeft: "28px",
    },
    contactUs: {
        //Only show when the mobile menu is showing instead of the footer
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
}));

const SocialShareSection: React.FC = () => {
    const classes = useStyles();

    const createButton = (item: JSX.Element, index: number) => {
        return (
            <SvgIcon
                className={classes.socialButton}
                viewBox="0 0 36 36"
                key={index}
            >
                {item}
            </SvgIcon>
        );
    };

    const buttons = [
        <a href="https://www.facebook.com/profile.php?id=100088382200702">
            <Facebook />
        </a>,
        <Twitter />,
        <Instagram />,
        <a href="https://www.linkedin.com/company/sondayy/">
            <Linkedin />
        </a>,
    ];

    return (
        <Container maxWidth="xl" className={classes.root}>
            <div className={classes.buttonContainer}>
                {buttons.map((item, index) => createButton(item, index))}
            </div>
            <Typography
                className={classes.contactUs}
                variant="caption"
                paragraph={true}
                align="center"
            >
                <Link
                    to="/contactus"
                    style={{ textDecoration: "none", color: "inherit" }}
                >
                    Contact Us
                </Link>
            </Typography>
            <Typography
                className="copyright"
                variant="caption"
                paragraph={true}
                align="center"
            >
                © Sondayy, Inc {new Date().getFullYear()}
            </Typography>
        </Container>
    );
};

export default SocialShareSection;
