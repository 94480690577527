import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import DefaultButton from "../DefaultButton/DefaultButton";
import { breakpoints } from "../TopLevelApp/theme";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
        paddingTop: "20px",
        paddingBottom: "40px",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-around",
        alignItems: "center",
        maxWidth: "100%",
        backgroundColor: "#F5F5F5",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    text: {
        flex: 3,
    },
    buttonContainer: {
        flex: 2,
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        minWidth: breakpoints.sm,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "30px",
        },
        "& *": {
            marginRight: "8px",
            [theme.breakpoints.down("sm")]: {
                marginRight: "unset",
                width: "max-content",
            },
        },
    },
    firstButton: {
        marginLeft: "35%",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "unset",
        },
    },
    secondaryButton: {
        background: "#FFFFFF",
    },
}));

const CtaSection: React.FC = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" className={classes.root}>
            <Typography
                className="text"
                variant="h5"
                paragraph={true}
                align="center"
            >
                Let's make every day Sondayy!
            </Typography>
            <div className={classes.buttonContainer}>
                <div className={classes.firstButton}>
                    <DefaultButton>
                        <Link
                            to={"/contactus"}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            Start the conversation as a City or County
                        </Link>
                    </DefaultButton>
                </div>
                <div>
                    <DefaultButton
                        variant="outlined"
                        className={classes.secondaryButton}
                        size="small"
                    >
                        <Link
                            to={"/travelerspage"}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            Sign Up as a Traveler
                        </Link>
                    </DefaultButton>
                </div>
            </div>
        </Container>
    );
};

export default CtaSection;
