import { createMuiTheme} from '@material-ui/core/styles';

/*
Media Query Breakpoints match Material UI
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
*/

const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 375, //phone
        md: 600,
        lg: 1280,
        xl: 1920
      }
    },
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#18A0FB',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: ["Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
      fontSize: 16,
      button: {
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 400
      }
    }
  });

  export const breakpoints = {...theme.breakpoints.values}

export default theme

  
   