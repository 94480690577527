import React, { useEffect } from "react";
// import ExitIntent from "../../hooksAndHelpers/exitIntent";
// import detectExitIntentViaScroll from "../../hooksAndHelpers/detectExitIntentViaScroll";
import "./TopLevelApp.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import HomePage from "../HomePage/HomePage";
import SingleRoutePage from "../SingleRoutePage/SingleRoutePage";
import RouteNotReadyPage from "../RouteNotReadyPage/RouteNotReadyPage";
import DriversAndTravelersPage from "../DriversAndTravelersPage/DriversAndTravelersPage";
import ContactUsPage from "../ContactUsPage/ContactUsPage";
import RouteLibraryPage from "../RouteLibraryPage/RouteLibraryPage";
import FaqPage from "../FaqPage/FaqPage";
// import ExitPopup from "../ExitPopup/ExitPopup";
import theme from "./theme";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        width: "auto",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-around",
        alignItems: "center",
    },
}));

function App() {
    const classes = useStyles();
    //commented code enables a user exit popup (also works pretty well on mobile)
    //save it in case you might need it later, but turning off for now
    // const [showExitPopup, setShowExitPopup] = useState(false);
    // const [exitPopupShownCount, setExitPopupShownCount] = useState(0);
    // let scrollExitPopupShownCount = useRef(0);

    // useEffect(() => {
    // 	/*show the ExitPopup when the user goes to the URL/tabs bar, but wait 6 seconds
    // 	before activating the listener
    // 	*/
    // 	const timer = setTimeout(() => ExitIntent({
    // 		threshold: 30,
    // 		eventThrottle: 200,
    // 		maxDisplays: 1,
    // 		onExitIntent: () => {
    // 			setShowExitPopup(true)
    // 			//prevents popup from disappearing when triggered again while popup is open
    // 			if (!showExitPopup) { setExitPopupShownCount(exitPopupShownCount + 1) }
    // 		},
    // 	}), 6000)
    // 	return () => clearTimeout(timer);
    // })

    // useEffect(() => {
    // 	/*show the ExitPopup after 2 minutes. If they've been on here 2 min they're
    // 	probably fairly invested. Also foolproof that it will eventually show mobile
    // 	users something
    // 	*/
    // 	const timer = setTimeout(() => {
    // 		setShowExitPopup(true)
    // 		if (!showExitPopup) { setExitPopupShownCount(exitPopupShownCount + 1) }
    // 	}, 120000);
    // 	return () => clearTimeout(timer);
    // })
    // /*Show the ExitPopup when the insured scrolls up very rapidly. Intended
    // to trigger when a mobile user flickscrolls up fast to get to the menu bar
    // as an indication of exit intent
    // */
    // useEffect(() => {
    // 	const options = {
    // 		triggerFunction: () => {
    // 			console.log('Exit Intent detected from Top Level App')
    // 			if (exitPopupShownCount < 2 && !showExitPopup) {
    // 				setExitPopupShownCount(exitPopupShownCount + 1);
    // 				scrollExitPopupShownCount.current++
    // 				setShowExitPopup(true);
    // 			}
    // 		}
    // 	}
    // 	detectExitIntentViaScroll(options)
    // })

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Container
                    className={classes.root}
                    maxWidth={"xl"}
                    disableGutters={true}
                >
                    {/* <ExitPopup
                        open={
                            showExitPopup &&
                            exitPopupShownCount < 2 &&
                            scrollExitPopupShownCount.current < 2
                        }
                        closeModalFunction={setShowExitPopup}
                    /> */}
                    <Router>
                        <QueryParamProvider ReactRouterRoute={Route}>
                            <ScrollToTop>
                                <Switch>
                                    <Route path="/driverspage">
                                        <DriversAndTravelersPage driverOrTraveler="driver" />
                                    </Route>
                                    <Route path="/travelerspage">
                                        <DriversAndTravelersPage driverOrTraveler="traveler" />
                                    </Route>
                                    <Route path="/routelibrary">
                                        <RouteLibraryPage />
                                    </Route>
                                    <Route path="/faqpage">
                                        <FaqPage />
                                    </Route>
                                    <Route path="/route">
                                        <SingleRoutePage />
                                    </Route>
                                    <Route path="/routenotready">
                                        <RouteNotReadyPage />
                                    </Route>
                                    <Route path="/contactus">
                                        <ContactUsPage />
                                    </Route>
                                    <Route exact path="/">
                                        <HomePage />
                                    </Route>
                                    <Route path="/striperefreshlandingscreenredirect">
                                        <ExternalRedirect externalUrl="sondayy://StripeRefreshLandingScreen" />
                                    </Route>
                                    <Route path="/stripereturnlandingscreenredirect">
                                        <ExternalRedirect externalUrl="sondayy://StripeReturnLandingScreen" />
                                    </Route>
                                </Switch>
                            </ScrollToTop>
                        </QueryParamProvider>
                    </Router>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default App;

const ExternalRedirect = ({ externalUrl }: { externalUrl: string }) => {
    useEffect(() => {
        window.location.replace(externalUrl);
    }, [externalUrl]);
    return (
        <div>
            <a href={externalUrl}>
                Click here to return to Sondayy Mobile App and finish process
            </a>
        </div>
    );
};
