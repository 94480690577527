import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => (
  {
    root: {
      margin: theme.spacing(1),
    }
  }
));

interface defaultButtonProps extends ButtonProps {
  children?: React.ReactNode,
  variant?: "outlined" | "contained"
  className?: string
}

const DefaultButton: React.FC<defaultButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        variant={props.variant || "contained"}
        color="primary"
        className={props.className || ""}
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    </div>
  );
}

export default DefaultButton