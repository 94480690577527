import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import HeaderNav from '../HeaderNav/HeaderNav';
import SubPageOverviewSection from '../SubPageOverviewSection/SubPageOverviewSection';
import FounderQuoteSection from '../FounderQuoteSection/FounderQuoteSection';
import CtaSection from '../CtaSection/CtaSection';
import FooterNav from '../FooterNav/FooterNav';
import SocialShareSection from '../SocialShareSection/SocialShareSection';
import { Typography } from '@material-ui/core';
import pageChoices from '../../assets/pageChoices';


const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            }
        },
        subPageOverviewSection: {
            marginBottom: "80px"
        },
    }),
);

const RouteNotReadyPage: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <HeaderNav
                sections={pageChoices}
            />
            <SubPageOverviewSection
                className={classes.subPageOverviewSection}
                pageTitle="Sorry - we're not ready for that route yet 😞"
                subtitle="We don't have enough signups on that route right now"
                image="routeNotReady"
                benefits={[]}
            ></SubPageOverviewSection>
            <Typography
                variant="subtitle1"
                paragraph={true}
                align="center"
            >
                In order for Drivers to see a benefit and for Travelers to get paid we need to have enough of each signed up on your route. Once we grow enough interest in a particular route we’ll let you know and then start everyone at the same time. On that day... traffic will be lower!
            </Typography>
            <Typography
                variant="subtitle1"
                paragraph={true}
                align="center"
            >
                If you want to help us get there faster, please encourage your friends to sign up too. You’re helping them and your community!
            </Typography>
            <FounderQuoteSection></FounderQuoteSection>
            <CtaSection></CtaSection>
            <FooterNav
                sections={pageChoices}
            ></FooterNav>
            <SocialShareSection></SocialShareSection>
        </>

    )
}

export default RouteNotReadyPage

